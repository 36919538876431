<template>
  <refund-table :columns="columns" />
</template>
<script>
import { REFUND_STATES } from '@/enums/refunds'
import { PAYMENT_METHODS } from '@/enums/orders'
import RefundTable from '@/components/entity/refund/RefundTable.vue'

export default {
  components: {
    RefundTable,
  },
  data() {
    return {
      columns: [
        {
          name: 'order',
          label: 'Order',
          filterable: {
            type: 'entity', queryType: 'orders', value: 'id', label: 'order_number', search: 'order_number',
          },
        },
        {
          name: 'user',
          label: 'User',
          filterable: {
            type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email',
          },
        },
        {
          name: 'form',
          label: 'Info',
        },
        {
          name: 'processor',
          label: 'Processor',
          filterable: {
            type: 'multienum',
            enum: PAYMENT_METHODS,
          },
          visible: true,
        },
        {
          name: 'price',
          label: 'Price',
        },
        {
          name: 'state', label: 'State', filterable: { type: 'multienum', enum: Object.keys(REFUND_STATES) }, default: ['requested', 'declined'],
        },
        {
          name: 'createdAt',
          label: 'Created at',
        },
        {
          name: 'updatedAt',
          label: 'Updated at',
        },
        {
          name: 'comment',
          label: 'Comment',
        },
      ],
    }
  },
}
</script>
